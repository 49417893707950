import { http } from '@/http/axios.js'

// 列表
export function getListAPI(data) {
  return http({
    url: `/api/manage/user/list`,
    method: 'POST',
    data
  })
}


export function getDetailAPI(id) {
  return http({
    url: `/api/manage/user/detail`,
    method: 'post',
    data: { id }
  })
}

export function getUserProductInfo(uid) {
  return http({
    url: `/api/manage/user/getUserProductInfo`,
    method: 'post',
    data: { uid }
  })
}


export function getChildUser(pid) {
  return http({
    url: `/api/manage/user/getChildList`,
    method: 'post',
    data: { pid }
  })
}

export function getOrderListAPI(uid) {
  return http({
    url: `/api/manage/order/list`,
    method: 'post',
    data: { uid , pay_status: 20}
  })
}

export function getBrowsingHistoryListAPI(data) {
  return http({
    url: `/api/manage/user/biaoxun/getLookList`,
    method: 'post',
    data
  })
}

